import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import "./App.css";
import { LinkContainer } from "react-router-bootstrap";
import AuthContext from './auth-context';
import Routes from "./Routes";
import {API, Auth} from "aws-amplify";
import {openDB} from "idb";

const App = props => {

  const [authenticated, setAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const dbPromise = createIndexedDB();

  async function getSession() {
    try {
      await Auth.currentSession();
      setAuthenticated(true);
    } catch(e) {
      if (e !== 'No current user') {
        alert(e);
        console.log(e);
      }
    }
  }

  useEffect(() => {
    getSession() ? setIsAuthenticating(false) : setIsAuthenticating(true);
  });

  const login = async () => {

    setAuthenticated(true);
    try {
      let response = await API.get("dev-serp-api-v5", '/customer');
      if (response.length) {
        saveCustomerRecordLocally(response);
      }
    } catch(e) {
      console.log(e);
    }

    setTimeout(() => {
      props.history.replace('/');
    }, 500);
  };

  const logout = async () => {
    await Auth.signOut();
    setAuthenticated(false);
    setTimeout(() => {
      props.history.replace('/');
    }, 1000);
  };

  return (
  !isAuthenticating &&
  <div className="App container">
    <AuthContext.Provider value={{authenticated: authenticated, login: login}}>

      <Navbar fixedTop collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/"><span className="logo-gld">SE</span>Rec<span className="logo-gld">o</span>n</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            {
              authenticated ? null :
                // using array items instead of <React.Fragment /> so collapseOnSelect will work
                [
                  <LinkContainer key={1} to="/signup">
                    <NavItem eventKey={1.1}>SignUp</NavItem>
                  </LinkContainer>,
                  <LinkContainer key={2} to="/login">
                    <NavItem eventKey={2.2}>Login</NavItem>
                  </LinkContainer>
                ]
            }
            {
              authenticated ?

                [
                  /*
                  <LinkContainer key={3} to="/google">
                    <NavItem eventKey={3.3}>Google</NavItem>
                  </LinkContainer>,

                  <LinkContainer key={4} to="/yelp">
                    <NavItem eventKey={4.4}>Yelp</NavItem>
                  </LinkContainer>,
                  */

                  <NavDropdown key={5} eventKey={5.1} title="Reports" id="basic-nav-dropdown">
                    <LinkContainer to="/google">
                      <MenuItem eventKey={5.2}>Google</MenuItem>
                    </LinkContainer>
                    <LinkContainer to="/yelp">
                      <MenuItem eventKey={5.3}>Yelp</MenuItem>
                    </LinkContainer>
                    <MenuItem divider />
                    <LinkContainer to="/settings">
                      <MenuItem eventKey={5.4}>Settings</MenuItem>
                    </LinkContainer>
                  </NavDropdown>,

                  <LinkContainer key={6} to="/logout">
                    <NavItem eventKey={6.5} onClick={() => {
                      logout();
                    }}>Logout</NavItem>
                  </LinkContainer>,

                ]

                : null
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Routes/>
    </AuthContext.Provider>
  </div>
  );

  function saveCustomerRecordLocally(items) {
    if (!('indexedDB' in window)) {return null;}
    return dbPromise.then(db => {
      const tx = db.transaction('items', 'readwrite');
      const store = tx.objectStore('items');
      return Promise.all(items.map(item => store.put(item)))
        .catch(() => {
          tx.abort();
          throw Error('Customer record was not added to the store');
        });
    });
  }

  function createIndexedDB() {
    if (!('indexedDB' in window)) {return null;}
    return openDB('yelp-serp', 1, {
      upgrade(db, oldVersion, newVersion, transaction) {
        if (!db.objectStoreNames.contains('items')) {
          var itemsOS = db.createObjectStore('items', {keyPath: 'search_id'});
          itemsOS.createIndex('search_link', 'search_link');
        }
      },
    });
  }

};

export default withRouter(App);
