import React, { useContext, useEffect, useState } from "react";
import "./Yelp.css";
import AuthContext from '../auth-context';
import { API } from "aws-amplify";
import SearchResult from '../components/YelpSearchResult';
import { openDB } from 'idb';
import {ControlLabel, Form, FormControl, FormGroup, Radio } from "react-bootstrap";
import SearchChart from '../components/YelpSearchChart';

export default (props) => {

  const auth = useContext(AuthContext);

  let [serpItems, setSerpItems] = useState([]);
  let [termSelectState, setTermSelectState] = useState('error');
  let [term, setTerm] = useState('');
  let [location, setLocation] = useState('');
  let [locationSelectState, setLocationSelectState] = useState('error');
  let [displayType, setDisplayType] = useState('details');
  let [custRec, setCustRec] = useState(null);

  const dbPromise = createIndexedDB();

  useEffect( () => {
    if (auth.authenticated === true) {
      if (termSelectState === 'success' && locationSelectState === 'success') {
        getResults();
      }
    }
  }, [term, location]);

  useEffect(() => {
    if (auth.authenticated === true) {
      getCustomerRecord();
    }
  },[setCustRec]);

  const handleTermChange = (value) => {
    if (value !== 'select') {
      setTermSelectState('success');
      setTerm(value);
    } else {
      setTermSelectState('error');
    }
  };

  const handleLocationChange = (value) => {
    if (value !== 'select') {
      setLocationSelectState('success');
      setLocation(value);
    } else {
      setLocationSelectState('error');
    }
  };


  const getCustomerRecord = async () => {
    let response;
    try {
      let localResponse = await getLocalCustomerRecord();
      if (localResponse.length) {
        console.log('displaying locally cached customer data');
        setCustRec(localResponse);
      } else {
        console.log('getting data from network');
        response = await API.get("dev-serp-api-v5", '/customer');
        if(response.length) {
          setCustRec(response);
          saveCustomerRecordLocally(response);
        }
      }
    } catch(e) {
      console.log('ERROR: ' + e);
    }
  };


  /*
   indexDB first, then network
   network calls are using amplify to securely access an aws cognito/iam secured
    - api gateway which is serving a lambda function which then accesses dynamodb
  */
  const getResults = async () => {
    let response;
    try {
      let localResponse = await getSomeLocalItems();
      if (localResponse.length) {
        console.log('displaying locally cached data');
        setSerpItems(localResponse);
        response = await API.get("dev-serp-api-v5", `/yelp?term=${term}&location=${location}`);
        console.log('now getting new data from network and updating local cache');
        if(response.length) {
          setSerpItems(response);
          saveItemDataLocally(response);
        }
      } else {
        console.log('getting data from network');
        response = await API.get("dev-serp-api-v5", `/yelp?term=${term}&location=${location}`);
        if(response.length) {
          setSerpItems(response);
          saveItemDataLocally(response);
        }
      }
    } catch(e) {
      console.log(`API ERROR: ${e}`);
    }
  };

  if (!auth.authenticated) {
    props.history.push('/login');
    return null;

  } else {

    if (custRec !== null) {

      // deconstruct our needed data
      var [{customer_name, customer_url, customer_yelp_url, search_config: { terms, locations}}] = custRec;

      var selectTerms = [];
      selectTerms.push(<option key="0.5" value="select">Choose One</option>);
      terms.map((term, index) => {
        selectTerms.push(<option key={index} value={term}>{term}</option>)
        return selectTerms;
      });

      var selectLocations = [];
      selectLocations.push(<option key="0.5" value="select">Choose One</option>);
      locations.map((location, index) => {
        selectLocations.push(<option key={index} value={location}>{location}</option>)
        return selectLocations;
      });
    }

    return (
      <div className="Yelp">
        <div className="reportHeader">
          <h3 className="reportTitle">{(custRec != null ? customer_name : null)}<br />Yelp Position Report</h3>
          <div className="controls-container">
            <br />
            <p className="controls-info">(Choose the report term and location below)</p>
            <Form inline id="controls-form">
              <FormGroup controlId="formControlsSelectTerm" validationState={termSelectState}>
                <ControlLabel>Select Term</ControlLabel>
                <FormControl bsSize="sm" componentClass="select" placeholder="select" onChange={e => handleTermChange(e.target.value)}>
                  {selectTerms}
                </FormControl>
              </FormGroup>

              <FormGroup controlId="formControlsSelectLocation" validationState={locationSelectState}>
                <ControlLabel>Select Location</ControlLabel>
                <FormControl bsSize="sm" componentClass="select" placeholder="select" onChange={e => handleLocationChange(e.target.value)}>
                  {selectLocations}
                </FormControl>
              </FormGroup>
              <br />
              <FormGroup controlId="formControlsDisplayTypeRadio" onChange={e => handleDisplayRadioChange(e.target.value)}>
                <Radio name="displayType" title="Show Details" value="details" defaultChecked={true} inline>
                 Show Details
                </Radio>{' '}
                <Radio name="displayType" title="Show Chart" value="chart" inline>
                  Show Chart
                </Radio>{' '}
              </FormGroup>

            </Form>

          </div>
        </div>
        {
          // if details radio is selected show details view
          (displayType === 'details'
            ? <SearchResult
               serpItems={serpItems}
               engine='Yelp'
               customer_yelp_url={customer_yelp_url}
              />

              // otherwise show chart view
            : <SearchChart
               serpItems={serpItems}
               engine='Yelp'
               customer_name={customer_name}
               customer_url={customer_url}
               term={term}
               location={location}
              />
          )
        }
      </div>
    )
  }

  /////////// functions ///////////

  function createIndexedDB() {
    if (!('indexedDB' in window)) {return null;}
    return openDB('yelp-serp', 1, {
      upgrade(db, oldVersion, newVersion, transaction) {
        if (!db.objectStoreNames.contains('items')) {
          var itemsOS = db.createObjectStore('items', {keyPath: 'search_id'});
          itemsOS.createIndex('search_link', 'search_link');
        }
      },
    });
  }

  function saveItemDataLocally(items) {
    if (!('indexedDB' in window)) {return null;}
    return dbPromise.then(db => {
      const tx = db.transaction('items', 'readwrite');
      const store = tx.objectStore('items');
      return Promise.all(items.map(item => store.put(item)))
        .catch(() => {
          tx.abort();
          throw Error('Items were not added to the store');
        });
    });
  }

  /*
  function getAllLocalItems() {
    if (!('indexedDB' in window)) {return null;}
    return dbPromise.then(db => {
      const tx = db.transaction('items', 'readonly');
      const store = tx.objectStore('items');
      return store.getAll();
    });
  }
  */

  function getSomeLocalItems() {

    if (!('indexedDB' in window)) {return null;}

    return dbPromise.then(async db => {

      const tx = db.transaction('items', 'readonly');
      //const store = tx.objectStore('items');
      tx.objectStore('items');
      return await db.getAllFromIndex(
        'items',
        'search_link',
        `https://www.yelp.com/search?cflt=${term}&find_loc=${location}`);

      /* this works
      let cursor = await db.transaction('items').store.openCursor();
      while (cursor) {
        console.log(cursor.key, cursor.value);
        cursor = await cursor.continue();
      }
      */

      /* this works
      const value = await db.get('items', "2776f4b1-367d-11e9-b978-bbe8146f516e");
      console.log(value);
      */
    });

  }

  function getLocalCustomerRecord() {
    if (!('indexedDB' in window)) {
      return null;
    }

    return dbPromise.then(async db => {

      const tx = db.transaction('items', 'readonly');
      tx.objectStore('items');
      return await db.getAll(
        'items',
        'customer_record'
      );
      //return await db.get('items', 'customer_record')
    });
  }

  function saveCustomerRecordLocally(items) {
    if (!('indexedDB' in window)) {return null;}
    return dbPromise.then(db => {
      const tx = db.transaction('items', 'readwrite');
      const store = tx.objectStore('items');
      return Promise.all(items.map(item => store.put(item)))
        .catch(() => {
          tx.abort();
          throw Error('Customer record was not added to the store');
        });
    });
  }

  function handleDisplayRadioChange(value) {
    setDisplayType(value);
    //alert(`Display Type: ${value}`);
  }

}
