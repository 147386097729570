import React from "react";
import './GoogleSearchResult.css';


export default (props) => {

  let engine = props.engine;
  let serpItems = props.serpItems;
  let searchResults = [];

  serpItems = serpItems.sort(compareValues('search_link', 'asc'));

  const cleanSearchLink = (link) => {
    return link.toString().replace(/^.*=/, '') // beginning to equal sign
      .replace(/\+/g, ' ') // plus signs to spaces
      .replace(/%27/g, ''); // single quotes
  };

  const formatDate = (timestamp) => {
    let formatted = new Date(parseInt(timestamp));
    formatted = formatted.toDateString() + ' ' + formatted.toTimeString();
    return formatted;
  };


  // sort object values
  function compareValues(key, order='asc') {
    return function(a, b) {
      if(!a.hasOwnProperty(key) ||
        !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ?
          (comparison * -1) : comparison
      );
    };
  }

  // highlight customer url when found in results
  function findURL(resultItem) {
    try {
      if (resultItem) {
        if (resultItem.toLowerCase().includes('bestsandblasting.com')) {
          // wrap result in span for css display treatment
          resultItem = <span className="our-url">{resultItem}</span>;
          return resultItem;
        } else {
          // otherwise, return unmodified
          return resultItem;
        }
      } else {
        // item is null or undefined, do nothing for now
      }
    } catch (err) {
      console.error(err, err.stack);
    }
  }

  let displayPage = (page) => {
    try {
      // convert object to array
      if (page) {
        let pageArray = Object.entries(page);
        let pResults = [];
        pageArray.sort().forEach((pos, index) => {
          let myURL = new URL(`${pos[1]}`);
          let coreURL = myURL.hostname;
          pResults.push(<h5 key={index}>{pos[0]} : <a href={pos[1]} rel="noopener noreferrer" target="_blank">{findURL(coreURL)}</a></h5>);
        });
        return pResults;
      } else {
        return <p>There were no results for this page</p>;
      }
    } catch (err) {
      console.error(err, err.stack);
    }
  };

  serpItems.forEach((item, index) => {
    searchResults.push(
      <div key={index} className="searchResults">
        <h3>{`${engine} Search: `}<a href={item.search_link} rel="noopener noreferrer" target="blank">"{cleanSearchLink(item.search_link)}"</a></h3>
        <h5>{`${formatDate(item.search_end_time)}`}</h5>
        <div className="pageContainer">
          <h4>Page One:</h4>
          {displayPage(item.search_results.page_01)}
        </div>
        <div className="pageContainer">
          <h4>Page Two:</h4>
          {displayPage(item.search_results.page_02)}
        </div>
        <div className="pageContainer">
          <h4>Page Three:</h4>
          {displayPage(item.search_results.page_03)}
        </div>
        <br />
      </div>
    );
  });

  return searchResults;
}

