import React from "react";
import './GoogleReportHeader.css';


export default (props) => {

  let customerName = props.customerName;
  let engine = props.engine;
  let dataType = props.dataType;

  return (
    <div className="reportHeader">
      <h2>{customerName} {engine} {dataType} Report</h2>
    </div>
  )
}
