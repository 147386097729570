import React, { useContext, useEffect, useState } from "react";
import "./Google.css";
import AuthContext from '../auth-context';
import { API } from "aws-amplify";
import ReportHeader from '../components/GoogleReportHeader';
import SearchResult from '../components/GoogleSearchResult';
import { openDB } from 'idb';

export default (props) => {

  const auth = useContext(AuthContext);
  const [serpItems, setSerpItems] = useState([]);
  const dbPromise = createIndexedDB();

  // indexDB first, then network
  // network calls are using amplify to securely access an aws cognito/iam secured
  //  - api gateway which is serving a lambda function which then accesses dynamodb
  const getResults = async () => {
    let response;
    try {
      let localResponse = await getLocalItemData();
      if (localResponse.length) {
        console.log('displaying locally cached data');
        setSerpItems(localResponse);
        response = await API.get("dev-serp-api-v5", "/google");
        console.log('now getting new data from network and updating local cache');
        setSerpItems(response);
        saveItemDataLocally(response);
      } else {
        console.log('getting data from network');
        response = await API.get("dev-serp-api-v5", "/google");
        setSerpItems(response);
        saveItemDataLocally(response);
      }
    } catch(e) {
      console.log(`API ERROR: ${e}`);
    }
  };

  useEffect( () => {
    if (auth.authenticated === true) {
      getResults();
    }
  }, []);

  if (!auth.authenticated) {
    props.history.push('/login');
    return null;

  } else {

    return (
      <div className="Google">
        <ReportHeader customerName='Best Sandblasting' engine='Google' dataType='Recon'/>
        <SearchResult serpItems={serpItems} engine='Google'/>
      </div>
    )
  }

  /////////// functions ///////////

  function createIndexedDB() {
    if (!('indexedDB' in window)) {return null;}
    return openDB('google-serp', 1, {
      upgrade(db, oldVersion, newVersion, transaction) {
        if (!db.objectStoreNames.contains('items')) {
          const itemsOS = db.createObjectStore('items', {keyPath: 'search_id'});
          itemsOS.createIndex('search_link', 'search_link');
        }
      },
    });
  }

  function saveItemDataLocally(items) {
    if (!('indexedDB' in window)) {return null;}
    return dbPromise.then(db => {
      const tx = db.transaction('items', 'readwrite');
      const store = tx.objectStore('items');
      return Promise.all(items.map(item => store.put(item)))
        .catch(() => {
          tx.abort();
          throw Error('Items were not added to the store');
        });
    });
  }

  function getLocalItemData() {
    if (!('indexedDB' in window)) {return null;}
    return dbPromise.then(db => {
      const tx = db.transaction('items', 'readonly');
      const store = tx.objectStore('items');
      return store.getAll();
    });
  }

}
